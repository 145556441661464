<template>
  <div>
    <Navbar></Navbar>
    <NavbarScroll></NavbarScroll>
    <SmallBanner title="Sobre"></SmallBanner>
    <main class="main px-4 is-centered">
      <div class="container">
        <div class="columns">
          <div class="list column is-1"></div>
          <div class="column is-8">
            <div class="img-blog mb-4"></div>
            <div class="mb-6">
              <p class="title is-4">Lista de RH em Curitiba</p>
              <p>
                Encontre aqui as principais agências de emprego de Curitiba.
                Aqui você confere as vagas de todos os RHs em um só lugar!
                Clique no RH e confira as vagas disponíveis em cada agência!
              </p>
              <br><br>
              <ul>
                <li><a target="_blank" href="https://rhnossa.abler.com.br/?search[city]=Curitiba">RH Nossa</a></li>
              </ul>
              <br>
              <ul>
                <li><a target="_blank" href="https://www.imediattarh.com.br/">Grupo Imediatta</a></li>
              </ul>
              <br>
              <ul>
                <li><a target="_blank" href="https://agilidade.selecty.com.br/">Agilidade RH</a></li>
              </ul>
              <br>
              <ul>
                <li><a target="_blank" href="https://www.serecursoshumanos.com.br/vagas.php#container">Sé Recurso Humanos</a></li>
              </ul>
              <br>
              <ul>
                <li><a target="_blank" href="https://makejob.abler.com.br/#list-vacancy">Make Job</a></li>
              </ul>
              <br>
              <ul>
                <li><a target="_blank" href="https://www.rhcenter.com.br/pesquisar-vagas">RH Center</a></li>
              </ul>
              <br>
              <ul>
                <li><a target="_blank" href="https://www.gd9rh.com.br/candidatos/">GD9 RH</a></li>
              </ul>
              <br>
              <ul>
                <li><a target="_blank" href="https://candidato.luandre.com.br/">Luandre</a></li>
              </ul>
              <br>
              <ul>
                <li><a target="_blank" href="https://rhcapital.selecty.com.br/">RH Capital</a></li>
              </ul>
               <br>
              <ul>
                <li><a target="_blank" href="https://www.chessrh.com.br/banco_vagas">Chess Human Resources</a></li>
              </ul>
               <br>
              <ul>
                <li><a target="_blank" href="https://rhkaizen.tweezer.jobs/candidato/vaga/buscar_vaga/">RH Kaizen</a></li>
              </ul>
               <br>
              <ul>
                <li><a target="_blank" href="https://aprioriexcelenciaemrecursoshumanos.pandape.com.br/">A Priori RH</a></li>
              </ul>
               <br>
              <ul>
                <li><a target="_blank" href="https://precisionrh.webcv.com.br/">Preciosion RH</a></li>
              </ul>
               <br>
              <ul>
                <li><a target="_blank" href="https://www.randstad.com.br/empregos/">Randstad</a></li>
              </ul>
              <br>
              <ul>
                <li><a target="_blank" href="https://www.cassirh.com.br/vagas-disponiveis">Cassi RH</a></li>
              </ul>
              <br>
              <ul>
                <li><a target="_blank" href="https://www.rhbrasil.com.br/site/vagas.php">RH Brasil</a></li>
              </ul>
              <br>
              <ul>
                <li><a target="_blank" href="https://www.agnesrh.com.br/">Agnes</a></li>
              </ul>
              <br>
              <ul>
                <li><a target="_blank" href="https://facop.compleo.com.br/">Facop</a></li>
              </ul>
              <br>
              <ul>
                <li><a target="_blank" href="https://www.grupomariah.com.br/buscar-vagas.php">Grupo Mariáh</a></li>
              </ul>
              <br>
              <ul>
                <li><a target="_blank" href="https://www.employer.com.br/vagas/">Employer</a></li>
              </ul>
              <br>
              <ul>
                <li><a target="_blank" href="https://www.rhsaude.com.br/">RH Saúde</a></li>
              </ul>
            </div>
          </div>
          <div class="list column is-3">
            <Banner></Banner>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import NavbarScroll from '../components/NavbarScroll.vue';
import SmallBanner from '../components/SmallBanner.vue';
import Banner from '../components/Banner.vue';
import Footer from '../components/Footer';

export default {
  name: 'RH',
  components: {
    Navbar,
    NavbarScroll,
    SmallBanner,
    Banner,
    Footer,
  },
};
</script>

<style scoped>
</style>