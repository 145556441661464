var AWS = require("aws-sdk");
AWS.config.update({
  region: "us-east-1",
  accessKeyId: "AKIAW6NPMDBEGZLAILNF",
  secretAccessKey: "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"
});


export function upload(file, callback) {
    let s3 = new AWS.S3({ apiVersion: "2006-03-01" });
    const params = {
      Bucket: `vagas-curitiba-files`,
      Key: file.name,
      Body: file,
      ACL: 'public-read'
    };
    s3.upload(params, function(err, data) {
      if (err) {
        console.log(err);
        return callback(err);
      }
      return callback(data.Location);
    });
}