<template>
  <section>
    <ul class="pagination-list">
      <li v-for="index in totalPages" :key="index" @click="paginacao(index)">
        <a
          class="pagination-link is-current"
          aria-label="Page 1"
          aria-current="page"
          
        >{{index}}</a>
      </li>
    </ul>
  </section>
</template>

<script>
import vagas from '../api/service/vagasService';
export default {
  name: 'Pagination',
  components: {},
  data() {
    return {
      total: 0,
      limite: 20,
      skip: 1,
      currentPage: 0,
      itemsPerPage: 2,
    };
  },
  props: {
    totalVagas: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    totalPages: function() {
      return Math.ceil(this.$props.totalVagas / this.limite);
    },
  },
  methods: {
    paginacao(page) {
      vagas.paginacao(page, this.limite).then(res => {
        this.$emit('onUpdate', res.data)
      });
    },
    listarVagas() {
      vagas.listaVaga(localStorage.getItem('token')).then(res => {
        this.total = res.data;
      });
    },
    mounted() {
      this.listarVagas();
    },
  },
};
</script>
<style scoped>
.pagination-link.is-current {
    background-color: #48c489 !important;
    border-color: #48c489 !important;
    color: #fff !important;
}
</style>