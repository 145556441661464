<template>
  <section>
    <div class="card mb-6">
        <header class="card-header">
        <div class="card-footer-item is-flex is-justify-content-flex-start">
          <p class="title is-6 is-flex">
            <span class="material-icons navigate_before">navigate_before</span>
            <b class="ml-3 mt-1 has-text-link">{{ post.title }}</b>
          </p>
        </div>
      </header>
        <div class="card-content">
            <div class="media">
                <div class="media-left is-hidden-mobile">
                    <figure class="image is-96x96">
                        <div v-if="post.arquivo != null && post.arquivo.length > 0">
                            <img class="profile-img" :src="post.img" />
                        </div>
                        <div v-else>
                            <img class="profile-img" :src="post.img" />
                        </div>
                    </figure>
                </div>
                <div class="media-content">
                    <p class="subtitle is-6">
                        <span :inner-html.prop="post.body"></span>
                        <p class="subtitle is-6 is-flex is-justify-content-flex-end">
                            <time datetime="2016-1-1">
                               <b>Publicada em 30/09/2021</b>
                            </time>
                        </p>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Detail',
  components: {},
  data() {
    return {};
  },
  props: {
    post: {},
  },
};
</script>

<style scoped>
.card-header .title b {
  text-decoration: underline;
  margin-bottom: 3px;
}
.card-header .title b:hover {
  text-decoration: none;
}
.navigate_before {
  color: #48c489;
}

.image {
  border-radius: 5px;
}

.card {
  border: 1px solid white;
}

.card:hover {
  border: 1px solid rgb(230, 228, 228);
  cursor: pointer;
}

.has-text-link {
  color: #48c489 !important;
}
</style>