<template>
  <section class="body">
    <Navbar></Navbar>
    <NavbarScroll></NavbarScroll>
    <header class="header"></header>
    <main class="main px-4 is-centered">
      <div class="container">
        <div class="columns">
          <div class="list column is-1"></div>
          <div class="list column is-8">
            <List />
          </div>
          <div class="banner column is-3 is-hidden-mobile" style="">
            <Banner />
          </div>
        </div>
      </div>
    </main>

    <footer class="footer">
      <Footer />
    </footer>
  </section>
</template>
<script>
import Navbar from '../components/Navbar';
import NavbarScroll from '../components/NavbarScroll.vue'
import List from '../components/List';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import empresasJSON from '../api/empresas.json';

export default {
  name: 'App',
  components: {
    Navbar,
    NavbarScroll,
    List,
    Banner,
    Footer,
  },
  data() {
    return {
      empresas: empresasJSON,
    };
  },
};
</script>

<style scoped>
.image-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.test-image {
  width: 100%;
  height: 100%;
  background-image: url('../assets/bg-top.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.main {
  min-height: calc(100vh - 225px);
}
.has-text-link {
  color: #93bbaa !important;
}
.header {
  height: 50px !important;
}
.fixed-dimension {
  height: 200px;
  width: 200px;
  background-color: grey;
}

footer {
  padding: 0rem 0rem 0rem !important;
  bottom: 0 !important;
}
</style>