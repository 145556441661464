<template>
  <section>
    <div v-for="empresa in empresas" :key="empresa">
      <div v-if="showList" @click="show(empresa._id)">
        <div class="card mb-4">
          <!-- <header class="">
            <div class="card-footer-item is-flex is-justify-content-flex-start">
              <p class="title is-6 is-flex">
                <b class="ml-3">{{ empresa.nomeEmpresa }}</b>
              </p>
            </div>
          </header> -->
          <div class="card-content">
            <div class="media">
              <div class='rows'>
                <div class='row is-full'>
                  <div class="media-left">
                    <div class="columns">
                      <div class="column is-2">
                        <figure class="image is-3by2">
                          <div v-if="empresa.arquivo != null && empresa.arquivo.length > 0">
                            <img class="profile-img" :src="empresa.arquivo" />
                          </div>
                          <div v-else>
                            <img class="profile-img" src="https://bulma.io/images/placeholders/128x128.png" />
                          </div>
                        </figure>
                      </div>
                      <div class="column">
                        <p class="is-4 is-flex">
                          <b>{{ empresa.nomeEmpresa }}</b>
                        </p>
                        <span class="is-size-7">{{ empresa.cidade }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='row is-full'>
                  <div class="media-content">
                    <b>{{ empresa.cargo }}</b><br><br>
                    <p class="is-6" v-show="
  empresa.descricao != null && empresa.descricao.length > 0
">
                      <span :inner-html.prop="empresa.descricao | truncate(160, '...')"></span>
                    </p>
                    <br>
                    <p class="is-size-7 is-flex is-justify-content-flex-end">
                      <time datetime="2016-1-1">
                        <b>Publicada em {{ empresa.publicacao }}</b>
                      </time>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="detail" v-show="!showList" @click="show()">
      <Detail :vaga="vagaSelecionada" />
    </div>
    <div class="pagination ml-2 mt-5 mb-6" v-if="showList == true" role="navigation" aria-label="pagination">
      <Pagination @onUpdate="onUpdateList($event)" :totalVagas="totalVagas"></Pagination>
    </div>
    <loading :active.sync="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage" color="#023436">
    </loading>
  </section>
</template>

<script>
import Detail from './Detail';
import Pagination from './Pagination';
import vaga from '../api/service/vagasService';
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'List',
  components: {
    Detail,
    Pagination,
    Loading
  },
  data() {
    return {
      empresas: [],
      showList: true,
      idCard: String,
      token: localStorage.getItem('token'),
      totalVagas: 0,
      limite: 0,
      vagaSelecionada: {},
      isLoading: false,
      fullPage: true,
    };
  },
  created() { },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  methods: {
    show: function (id) {
      this.showList = !this.showList;
      this.vagaSelecionada = {};
      if (id != undefined) {
        const found = this.empresas.find((element) => element._id === id);
        this.vagaSelecionada = found;
      }
    },
    listarVagas() {
      this.isLoading = true;
      vaga.paginacao(0, this.limite).then((res) => {
        this.empresas = res.data.vagas;
        this.totalVagas = res.data.total;
        this.isLoading = false;
      });
    },
    onUpdateList(list) {
      this.empresas = list;
    },
  },
  mounted() {
    this.listarVagas();
  },
};
</script>

<style scoped>
.rows {
  display: flex;
  flex-direction: column;
}

.card-header .title b {
  margin-top: 3px;
}

.card-header {
  padding-top: 1px;
}

.card .media-content .title {
  text-decoration: underline;
}

.card .media-content .title:hover {
  text-decoration: none;
}

.card {
  border-radius: 8px;
    border: 1px solid #E0E6F7;
}

.card:hover {
  border: 1px solid rgb(216, 214, 214);
  cursor: pointer;
}

.profile-img {
  border-radius: 5px;
}

.navigate_before {
  color: red;
}

.has-text-link {
  color: #48c489 !important;
}
</style>