
import {http} from '../config'

export default{
    listaVaga:()=>{
        return http.get('vagas' )
    },

    salvar:(newServico , token)=>{
    return http.post('vagas', newServico, { headers: {"Authorization" : `Bearer ${token}`} } )},
    
      paginacao:(page,limite)=>{
        return http.get('vagas/pagina?page='+page+'&limite='+limite)
      }
  
}