<template>
  <div>
    <div class="background-nav"></div>
    <div class="container">
      <div class="columns">
        <div class="column is-11 is-offset-1">
          <nav class="nav mt-4 is-flex is-align-items-center">
            <router-link to="/">
              <img class="logo" src="../assets/logo_navbar.png" alt="" />
            </router-link>
            <ul class="is-flex ml-6 is-hidden-mobile is-hidden-tablet-only">
              <li class="mr-6">
                <router-link to="/sobre"> Sobre </router-link>
              </li>
              <li class="mr-6">
                <router-link to="/dicas" style="text-transform: none;"> Dicas e Empregabilidade </router-link>
              </li>
              <li class="mr-6">
                <router-link to="/rh"> Lista RH </router-link>
              </li>
            </ul>

            <div v-if="showButton" class="navbar-end" v-show="showButton">
              <div class="navbar-item">
                <div class="buttons">
                  <a
                    v-if="btnPublicar == true"
                    @click="show"
                    class="button is-info is-flex is-align-items-center"
                  >
                    <span class="icon is-small">
                      <i class="material-icons">add</i>
                    </span>
                    <b class="publicar-vagas">Publicar Vaga</b>
                  </a>
                  <a v-else @click="show" class="button is-success">
                    <span class="icon is-small">
                      <i class="material-icons">arrow_back</i>
                    </span>
                    <b>Voltar</b>
                  </a>
                </div>
              </div>
            </div>
            <div
              v-else
              class="navbar-end is-hidden-mobile is-hidden-tablet-only"
            >
              <div class="navbar-item">
                <div class="buttons">
                  <button class="button is-success is-outlined">
                    <router-link
                      style="text-decoration: none; color: inherit"
                      to="/Admin"
                    >
                      Entrar
                    </router-link>
                  </button>
                </div>
              </div>
            </div>

            <a
              @click="menu"
              role="button"
              class="navbar-burger"
              aria-label="menu"
              aria-expanded="false"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </nav>

          <div class="responsive-nav">
            <ul>
              <li class="title is-2">
                <router-link to="/sobre"> Sobre </router-link>
              </li>
              <li class="title is-2">
                <router-link to="/dicas" style="text-transform: none;"> Dicas e Empregabilidade </router-link>
              </li>
              <li class="title is-2">
                <router-link to="/rh"> Lista RH </router-link>
              </li>
              <li>
                <button class="button is-success is-outlined">
                  <router-link
                    style="text-decoration: none; color: inherit"
                    to="/Admin"
                  >
                    Entrar
                  </router-link>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      btnPublicar: true,
    };
  },
  props: {
    showButton: null,
  },
  components: {},
  methods: {
    show: function () {
      this.$root.$emit('showForm');
      this.btnPublicar = !this.btnPublicar;
    },
    menu: function () {
      Array.from(document.querySelectorAll('.navbar-burger')).map((item) =>
        item.classList.toggle('is-active'),
      );
      document.querySelector('.responsive-nav').classList.toggle('is-active');
    },
  },
};
</script>

<style scoped>
.logo {
  height: 55px;
}

.nav li {
  text-transform: capitalize;
}

.nav li a {
  /* color: rgba(0, 0, 0, 0.5); */
  color: #fff;
  font-family: 'Montserrat';
  transition: all ease 0.3s;
}

.nav li a:hover {
  /* color: rgb(129, 107, 107); */
  color: rgba(255, 255, 255, 0.6);
}

.background-nav {
  width: 100%;
  position: absolute;
  background-color: #023436;
  /* background-color: whitesmoke; */
  /*background: linear-gradient(116.08deg, #023436 31.28%, #40916C 100.82%);*/
  height: 90px;
  box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.2);
}

.responsive-nav {
  display: none;
  transition: all ease 0.6s;
}

.responsive-nav.is-active {
  position: absolute;
  background-color: #023436;
  top: 111px;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.responsive-nav ul {
  text-align: center;
  height: 60%;
}

.responsive-nav a {
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .responsive-nav.is-active {
    display: none;
  }
}
</style>
