<template>
  <section>
    <div class="card mb-4">
      <div class="card-content">
        <div class="profile">
          <img
            class="profile-img"
            src="https://bulma.io/images/placeholders/128x128.png"
          />
          <p class="profile-content">
            <strong>{{ logUser.nome }}</strong>
            <br />
            <small>{{ logUser.email }}</small>
          </p>
        </div>

        <aside class="menu">
          <p class="menu-label">MENU</p>
          <ul class="menu-list">
            <li>
              <a
                class="is-active has-background-light has-text-link is-flex is-align-items-center"
              >
                <span class="material-icons navigate_before"
                  >navigate_next</span
                >
                <span>Vagas</span>
              </a>
            </li>
            <li><a>Academy</a></li>
            <li><a>Eventos</a></li>
            <li><a>Contatos</a></li>
            <li @click="sair"><a>Sair</a></li>
          </ul>
        </aside>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Banner",

  data() {
    return {
      logUser: JSON.parse(localStorage.getItem("user")),
    };
  },
  methods: {
    sair() {
      localStorage.removeItem("user"), localStorage.removeItem("token");
      this.$router.push({ name: "home" });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.menu-list li a {
  border-radius: 5px;
  margin: 7px 0;
}
.profile {
  text-align: center;
}
.profile-content {
  margin-bottom: 20px;
}
.profile-img {
  border-radius: 50%;
  width: 140px;
  margin-bottom: 10px;
  margin-top: 35px;
}
</style>
