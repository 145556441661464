<template>
  <UserForm/>
  
</template>

<script>
import UserForm from '../components/UserForm'
export default {
    name: 'CadastroUsuario',
    components:{
        UserForm,
    }

}
</script>

<style>

</style>