import {http} from '../config'

export default{
    login:(user)=>{
        return http.post('auth/login',user)
    },
    cadastro:(user)=>{
        return http.post('usuario',user)
    }
  }

   