import Vue from "vue";
import Router from "vue-router"
import Home from "./views/Home.vue"
import Admin from "./views/Admin.vue"
import Vagas from "./views/Vagas.vue"
import Sobre from "./views/Sobre.vue"
import Blog from "./views/Blog.vue"
import RH from "./views/RH.vue"
import CadastroUsuario from "./views/Cadastro.vue"

Vue.use(Router)
console.log(process.env.BASE_URL)

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: Home,
      name:'home',
    },
    {
      path: "/sobre",
      component: Sobre,
      name:'sobre',
    },
    {
      path: "/admin",
      component: Admin,
      name:'admin',
    },
    {
      path: "/admin/vagas",
      component: Vagas,
      name:'vagas',
      
    },
    {
      path: "/cadastro",
      component: CadastroUsuario,
      name:'cadastro'
    },
    {
      path: "/dicas",
      component: Blog,
      name: 'dicas'
    },
    {
      path: "/rh",
      component: RH,
      name: 'rh'
    }
  ]
})