<template>
  <section class="body">
    <div class="login mx-3">
      <form action="admin/vagas" class="box">
        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input
              class="input"
              type="email"
              placeholder="alex@example.com"
              required
              name="email"
              v-model="user.email"
            />
          </div>
        </div>

        <div class="field">
          <label class="label">Senha</label>
          <div class="control">
            <input
              class="input"
              type="password"
              placeholder="********"
              required
              name="senha"
              v-model="user.senha"
            />
          </div>
        </div>
      </form>
      <button
        class="button is-info is-fullwidth is-medium mt-5"
        style="background-color:#023436"
        @click.prevent="login"
      >
        <b>Entrar</b>
      </button>
    </div>
  </section>
</template>

<script>
import usuario from "../api/service/usuarioService";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      user: {},
    };
  },
  methods: {
    login() {
      usuario
        .login(this.user)
        .then((res) => {
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("token", res.data.token.access_token );
          // // redireciona para rota de vagas          
          this.$router.push({ name: "vagas" });
        })
        .catch((res) => {
          this.$buefy.dialog.alert({
            title: "Erro",
            message: res.message,
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        });
    },
    cadastra() {
      this.$router.push({ name: "cadastro" });
    },
  },
};
</script>
<style>
.login {
  max-width: 400px;
  margin: 0 auto;
  padding-top: 7vh;
}
</style>