<template>
  <section>
    <div class="card">
      <header class="card-header is-flex is-flex-direction-column">
        <div class="card-content banner">Siga o Vagas Curitiba nas redes sociais</div>
        <div class="card-content banner socialMedia">
          <a target="_blank" href="https://www.facebook.com/vagasemcuritibaa">
            <i class="fab fa-facebook-square mr-3" style="color: #023436; font-size: 2em"></i>
          </a>
          <a target="_blank" href="https://www.instagram.com/vagasemcuritiba/">
            <i class="fab fa-instagram" style="color: #023436; font-size: 2em"></i>
          </a>
        </div>
      </header>
    </div>
  </section>
</template>

<script>
export default {
  name: "Banner",
};
</script>

<style scoped>
  .socialMedia.fab {
    color: blue;
  }
</style>
