<template>
  <div>
    <Navbar></Navbar>
    <NavbarScroll></NavbarScroll>
    <SmallBanner title="Sobre"></SmallBanner>
    <main class="main px-4 is-centered">
      <div class="container">
        <div class="columns">
          <div class="list column is-1"></div>
          <div class="column is-8">
            <div class="img-blog mb-4"></div>
            <div class="mb-6">
              <p class="title is-4">Qual a missão do site Vagas em Curitiba?</p>
              <p>
                O Vagas em Curitiba surgiu da necessidade de fornecer um espaço
                no qual as pessoas que buscam emprego em Curitiba e Região
                possam acessar várias vagas em um só lugar, aumentando a
                quantidade de candidaturas e a possibilidade de conseguir uma
                recolocação no mercado de trabalho. Diariamente, divulgamos
                vagas em diversas áreas: operacionais, técnicas e
                administrativas. Além disso, divulgamos vagas de estágio em
                Curitiba e Região, para você que está estudando ter mais
                possibilidade de ingressar no mercado de trabalho e aliar a sua
                formação à prática profissional. Aqui também realizamos a
                divulgação de vagas de aprendiz em Curitiba e Região para você
                jovem ter acesso à sua primeira oportunidade de registro
                profissional. A inclusão também faz parte da missão do Vagas em
                Curitiba: divulgamos vagas de PCD para gerar mais oportunidades
                às Pessoas com Deficiência de acesso ao mercado de trabalho.<br>
                Aqui também deixamos os canais em aberto para você recrutador
                aproveitar a divulgação de vaga gratuita! <br><br>O nosso site é feito
                por especialistas na área de Recrutamento e Seleção, então
                entendemos as suas dificuldades para fechar uma vaga. Conte com
                a nossa ajuda para divulgar sua vaga e aumentar o número de
                currículos e candidaturas à suas vagas. Temos certeza que terá
                mais agilidade e sucesso em seu processo seletivo. Entre em
                contato conosco e divulgue as suas vagas sem custo em nosso
                site, podendo aparecer ainda nas divulgações em nossas redes
                sociais.
              </p>
            </div>

            <div class="mb-6">
              <p class="title is-4">Orientações para a utilização do site</p>
              <p>
                Atenção: este site é uma ferramenta gratuita para os usuários
                sejam candidatos ou recrutadores. Nenhum valor é cobrado para
                divulgar a vaga ou para acessar as vagas divulgadas. O nosso
                objetivo é auxiliar tanto recrutadores quanto candidatos e por
                isso temos bastante atenção às vagas que são divulgadas,
                verificando a existência e/ou procedência das vagas. Nossas
                fontes são de divulgações públicas em sites confiáveis de
                empresas, RHs e redes sociais. <br><br>O vagas em Curitiba deixa a dica:
                fique atento à sites que prometem garantia de emprego, que não
                tem link seguro ou e-mail da empresa para candidatura ou envio
                de currículo. Jamais mande seu currículo para e-mail que não
                seja vinculado à empresa que esta divulgando a vaga. Se você
                acessar alguma vaga ou receber algum contato de vaga que lhe
                pareça suspeito, denuncie! Infelizmente a Internet está repleta
                de golpistas tentando se aproveitar da necessidade das pessoas.
                <br><br>O site Vagas em Curitiba não recebe currículos e não participa
                dos processos seletivos das vagas divulgadas. É de
                responsabilidade de cada empresa receber os currículos e
                conduzir os seus processos, bem como dar retorno aos candidatos
                participantes. Também é de responsabilidade do candidato se
                atentar a forma de inscrição no processo seletivo para a vaga de
                seu interesse. Veja se a empresa está solicitando: enviar
                currículo para o e-mail informado, cadastrar currículo no
                link/site, comparecer à um determinado local, entrar em contato
                por telefone, etc. O importante é fazer da forma correta que a
                empresa está pedindo, para não correr o risco de ficar de fora
                do processo seletivo. <br><br>Além disso, tenha bastante atenção na hora
                de mandar ou cadastrar o currículo: garanta que seus telefones
                de contato estão atualizados para a empresa poder entrar em
                contato com você! Já pensou ter um currículo que apresenta todos
                os requisitos da vaga, mas não tem telefone de contato ou o
                contato não está correto? Você seria eliminado do processo por
                falta de contato! Conseguir uma vaga de emprego começa muito
                antes da entrevista! <br><br>Geralmente, divulgamos vagas para Curitiba
                e Região todos os dias em nosso site e para visualizar as vagas
                basta acessar a nossa página de divulgação de vagas. As vagas
                estão divulgadas por ordem de data da mais recente para a mais
                antiga. Então, preste atenção na data em que a vaga foi
                divulgada, pois vagas mais recentes significa que o processo
                seletivo foi aberto recentemente. Vagas muito antigas
                (divulgadas há mais de uma semana) normalmente já estão com o
                processo seletivo em andamento ou muitas vezes já foram fechadas
                pelas empresas. <br><br>O Vagas em Curitiba não tem acesso à esse
                controle das empresas se a vaga ainda está em aberto ou já foi
                fechada (a menos que a empresa entre em contato e solicite
                encerrar a divulgação da vaga), portanto, cabe à você candidato
                ter o bom senso na hora de candidatar as vagas. Assim como é
                importante lembrar: se candidate apenas se atende aos requisitos
                da vaga e siga a maneira correta de se candidatar conforme a
                empresa coloca na divulgação. Isso aumentará suas chances de ser
                chamado para participar do processo. Fique atento às nossas
                divulgações e boa sorte!
              </p>
            </div>
          </div>
          <div class="list column is-3">
            <Banner></Banner>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import NavbarScroll from '../components/NavbarScroll.vue';
import SmallBanner from '../components/SmallBanner.vue';
import Banner from '../components/Banner.vue';
import Footer from '../components/Footer';

export default {
  name: 'Sobre',
  components: {
    Navbar,
    NavbarScroll,
    SmallBanner,
    Banner,
    Footer,
  },
};
</script>

<style scoped>
</style>