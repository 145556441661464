<template>
    <div>
        <div class="smallbanner mb-6">
            <div  class="container is-flex is-align-items-center">
                <div class="columns">
                    <div class="list column is-1"></div>
                    <div class="column is-10 is-offset-7">
                        <!-- <h2 class="title is-2">
                            {{ title }}
                        </h2> -->
                    </div>
                    <div class="list column is-1"></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['title' ]
}
</script>

<style>
    .smallbanner {
        width: 100%;
        display: flex;
    }

    .smallbanner h2 {
        font-size: 1.8em;
    }
</style>