<template>
  <section>
    <div class="card mb-4">
      <header class="card-header">
        <div class="card-footer-item is-flex is-justify-content-flex-start">
          <p class="title is-6 is-flex">
            <span class="material-icons navigate_before">navigate_before</span>
            <b class="ml-3 mt-1 has-text-link">{{ vaga.nomeEmpresa }}</b>
          </p>
        </div>
      </header>

      <div class="card-content">
        <div class="media">
          <div class="media-left is-hidden-mobile">
            <figure class="image is-96x96">
              <div v-if="vaga.arquivo != null && vaga.arquivo.length > 0">
                <img class="profile-img" :src="vaga.arquivo" />
              </div>
              <div v-else>
                <img class="profile-img" src="https://bulma.io/images/placeholders/128x128.png" />
              </div>
            </figure>
          </div>

          <div class="media-content">
            <p class="subtitle is-4">
              <b>{{ vaga.cargo }}</b>
            </p>
            <p class="subtitle is-6" v-show="vaga.descricao != null && vaga.descricao.length > 0">
              <span v-html="vaga.descricao"></span>
            </p>
            <p class="subtitle is-6">
              <b>Cidade:</b>
              {{ vaga.cidade }}
            </p>

            <p class="subtitle is-6">
              <b>Email:</b>
              <a :href="'mailto:' + email">{{ vaga.email }}</a>
            </p>

            <p class="subtitle is-6 is-flex is-justify-content-flex-end">
              <time datetime="2016-1-1">
                <b>Publicada em {{ vaga.publicacao }}</b>
              </time>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Modal',
  components: {},
  data() {
    return {};
  },
  props: {
    vaga: {},
  },
};
</script>

<style scoped>
.card-header .title b {
  text-decoration: underline;
  margin-bottom: 3px;
}
.card-header .title b:hover {
  text-decoration: none;
}
.navigate_before {
  color: #48c489;
}

.image {
  border-radius: 5px;
}

.card {
  border: 1px solid white;
}

.card:hover {
  border: 1px solid rgb(230, 228, 228);
  cursor: pointer;
}

.has-text-link {
  color: #48c489 !important;
}
</style>