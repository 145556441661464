<template>
  <section class="body">
    <header class="nav-bar">
      <Navbar showButton="true"/>
    </header>

    <main class="main px-4">
      <div class="container">
        <div class="columns">
          <div class="menu column is-3 is-hidden-mobile is-offset-1">
            <Menu />
          </div>
          <div v-if="showList == true" class="list column is-7">
            <List />
          </div>
          <div v-else class="form column is-7">
            <Form />
          </div>
        </div>
      </div>
    </main>

    <div class="footer">
      <Footer />
    </div>
  </section>
</template>

<script>
import Navbar from "../components/Navbar";
import Menu from "../components/Menu";
import List from "../components/List";
import Form from "../components/Form";
import Footer from "../components/Footer";

export default {
  name: "Cadastro",
  components: {
    Navbar,
    Menu,
    List,
    Form,
    Footer,
  },
  data() {
    return {
      showList: true,
    };
  },
  methods: {
    show: function () {
      this.showList = !this.showList;
    },
  },
  mounted() {
    this.$root.$on("showForm", () => {
      this.show();
      console.log("mensagem recebida de Navbar");
    });
  },
};
</script>

<style scoped>
.main {
  min-height: calc(100vh - 280px);
}
</style>