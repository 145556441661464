<template>
  <section class="body">
    <Navbar></Navbar>
    <div class="backround-color"></div>
    <header class="login">
      <Login />
    </header>
    <Footer />
  </section>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Login from "../components/Login";
import Footer from "../components/Footer";
export default {
  name: "Admin",
  components: {
    Footer,
    Login,
    Navbar
  },
};
</script>

<style scoped>
.backround-color {
  background-color: #fafafa;
  min-height: 100vh;
  position: absolute;
  width: 100%;
  z-index: -9999;
}
.footer {
  margin-top: 3vh;
}
</style>