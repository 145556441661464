<template>
  <div class="card mb-5">
    <div class="card-content">
      <div class="field">
        <label class="label">Nome Da Empresa</label>
        <div class="control">
          <input class="input" type="text" placeholder="Ex: Bluelogic" v-model="vaga.nomeEmpresa" />
        </div>
      </div>

      <div class="field">
        <label class="label">Imagem da vaga</label>
        <div class="control">
          <div class="file has-name">
            <label class="file-label">
              <input class="file-input" type="file" name="resume" @change="onFileChange" />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">Escolha um arquivo...</span>
              </span>
              <span class="file-name">{{fileName}}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <div class="label">Cargo</div>
            <p class="control is-expanded">
              <input class="input" type="text" placeholder="Ex: Programador" v-model="vaga.cargo" />
            </p>
          </div>
          <div class="field">
            <div class="label">Cidade</div>
            <p class="control is-expanded">
              <input class="input" type="text" placeholder="Ex: Curitiba" v-model="vaga.cidade" />
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input
                class="input"
                type="email"
                placeholder="email@dominio.com"
                value
                v-model="vaga.email"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Atividades</label>
        <quill-editor ref="myQuillEditor" v-model="vaga.descricao" :options="editorOption"/>
      </div>
      <div class="field">
        <div class="control" @click.prevent="salvar">
          <button class="button is-info is-fullwidth is-medium mt-5">
            <b>Salvar Vaga</b>
          </button>
        </div>
      </div>
    </div>
    <div class="loading columns is-vcentered" v-show="isLoading">
      <div class="column is-three-fifths is-offset-one-fifth has-text-centered">
        <a class="button is-loading" style="width: 3rem; height: 3rem;"></a>
      </div>
    </div>
  </div>
</template>

<script>
import vagas from '../api/service/vagasService';
import * as s3 from '../utils/upload-s3';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';
export default {
  name: 'Form',
  components: {
    quillEditor,
  },
  data() {
    return {
      vaga: {},
      fileName: '',
      file: null,
      isLoading: false,
      editorOption: {
        placeholder: 'Seu texto aqui meu amor....',
      },
    };
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  methods: {
    salvar() {
      this.vaga.descricao =
        this.vaga.descricao == null || this.vaga.descricao.length == 0
          ? ' '
          : this.vaga.descricao;
      this.vaga.requisito =
        this.vaga.requisito == null || this.vaga.requisito.length == 0
          ? ' '
          : this.vaga.requisito;

      this.isLoading = true;
      console.log(this.vaga);
      vagas
        .salvar(this.vaga, localStorage.getItem('token'))
        .then(() => {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            title: 'Salvo com sucesso ',
            message: 'A vaga foi salvo com secesso!',
            confirmText: 'ok!',
          }),
            (this.vaga = {});
        })
        .catch(res => {
          this.isLoading = false;
          this.$buefy.dialog.alert({
            title: 'Erro',
            message: res.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true,
          });
        });
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.fileName = files[0].name;
      this.uploadFile(files[0]);
    },
    uploadFile(file) {
      if (file != null) {
        this.isLoading = true;
        s3.upload(file, url => {
          this.isLoading = false;
          this.vaga.arquivo = url;
        });
      }
    },
  },
};
</script>
<style>
.ql-container {
  min-height: 300px;
}
.loading {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
}
</style>