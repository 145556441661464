<template>
  <footer class="footer">
    <div class="container">

      <div class="columns">
        <div class="column is-10 is-offset-1">
          <div class="columns  mt-4">
            <div class="column is-half">
              <img class="logo" src="../assets/logo_navbar.png" alt="">
            </div>
            <div class="column is-half">
              <ul class="ml-6">
                <li class="mb-3">
                  <router-link to="/sobre"> Sobre </router-link>
                </li>
                <li class="mb-3">
                  <router-link to="/dicas" style="text-transform: none;"> Dicas e Empregabilidade </router-link>
                </li>
                <li class="mb-3">
                  <router-link to="/rh"> Lista RH </router-link>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>

    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  components: {},
};
</script>

<style scoped>
.uppercase {
  text-transform: uppercase;
}

.footer {
  background: linear-gradient(116.08deg, #023436 31.28%, #40916C 100.82%);
  height: 180px;
}

.logo {
  height: 75px;
}

.footer a {
  color: #fff;
}
</style>