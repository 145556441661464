<template>
  <section class="container centro">
    <h1>Cadrastro do usuário</h1>
    <form action="admin/vagas" class="box" @click.prevent="cadastra">
      <div class="field">
        <div class="label">Nome</div>
        <p class="control is-expanded">
          <input
            class="input"
            type="text"
            placeholder="João silva"
            v-model="usuario.nome"
          />
        </p>
      </div>
      <div class="field">
        <div class="label">Email</div>
        <p class="control is-expanded">
          <input
            class="input"
            type="email"
            placeholder="vagas@gmail.com"
            v-model="usuario.email"
          />
        </p>
      </div>
      <div class="field">
        <div class="label">Telefone</div>
        <p class="control is-expanded">
          <input
            class="input"
            type="text"
            placeholder="219934567"
            v-model="usuario.telefone"
          />
        </p>
      </div>
      <div class="field">
        <div class="label">Senha</div>
        <p class="control is-expanded">
          <input
            class="input"
            type="password"
            placeholder="Ex: Desenvolvedor Front-End"
            v-model="usuario.senha"
          />
        </p>
      </div>
      <button class="button is-info is-fullwidth is-medium mt-5">
        <b>Cadastrar</b>
      </button>
    </form>
  </section>
</template>
<script>
import user from "../api/service/usuarioService";
export default {
  name: "UserForm",
  data() {
    return {
      usuario: {},
    };
  },
  methods: {
    cadastra() { user.cadastro(this.usuario).then(() => {
        this.$router.replace({ name: "vagas" });
      });
    },
  },
};
</script>
<style scoped>
.centro {
  width: 60%;
  margin: auto;
  padding-top: 7vh;
}
.centro h1 {
  font-size: 2rem;
   
}
</style>
