<template>
  <div class="navbar-scroll">
    <div class="background-nav navbar-scroll"></div>
    <div class="container">
      <div class="columns">
        <div class="column is-11 is-offset-1">
          <nav class="nav mt-4 is-flex is-align-items-center">
            <router-link to="/">
              <img class="logo" src="../assets/logo_navbar.png" alt="" />
            </router-link>
            <ul class="is-flex ml-6 is-hidden-mobile">
              <li class="mr-6">
                <router-link to="/sobre"> Sobre </router-link>
              </li>
              <li class="mr-6">
                <router-link to="/dicas" style="text-transform: none;"> Dicas e Empregabilidade </router-link>
              </li>
              <li class="mr-6">
                <router-link to="/rh"> Lista RH </router-link>
              </li>
            </ul>

            <div class="navbar-end is-hidden-mobile is-hidden-tablet-only">
              <div class="navbar-item">
                <div class="buttons">
                  <button class="button is-success is-outlined">
                    <router-link style="text-decoration: none; color: inherit;" to="/Admin"> Entrar </router-link>
                  </button>
                </div>
              </div>
            </div>

            <a
              @click="menu"
              role="button"
              class="navbar-burger navbar-burger-new"
              aria-label="menu"
              aria-expanded="false"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </nav>

          <!-- <nav class="responsive-nav">
              <ul class="is-flex ml-6">
               <li class="title is-2 mr-6">
                  <router-link to="/sobre">
                    Sobre
                  </router-link>
                </li>
                <li class="title is-2 mr-6">
                  <router-link to="/blog">
                    Notícias
                  </router-link>
                </li>
              </ul>
            </nav> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    menu: function () {
      Array.from(document.querySelectorAll('.navbar-burger')).map((item) =>
        item.classList.toggle('is-active'),
      );
      document.querySelector('.responsive-nav').classList.toggle('is-active');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    scrollMenu: function () {
      if (
        document.body.scrollTop > 30 ||
        document.documentElement.scrollTop > 30
      ) {
        document.querySelector('.navbar-scroll').style.top = '0';
      } else {
        document.querySelector('.navbar-scroll').style.top = '-100%';
      }
    },
  },
  created() {
    window.addEventListener('scroll', this.scrollMenu);
  },
};
</script>

<style scoped>
.logo {
  height: 55px;
}
.navbar-scroll {
  position: fixed;
  top: -100%;
  /* top: 0; */
  transition: all ease 2s;
  width: 100%;
  background-color: #023436;
  /* background-color: whitesmoke; */
  /*background: linear-gradient(116.08deg, #023436 31.28%, #40916C 100.82%);*/
  height: 80px;
  box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.nav li {
  text-transform: capitalize;
}

.nav li a {
  /* color: rgba(0, 0, 0, 0.5); */
  color: #fff;
  font-family: 'Montserrat';
  transition: all ease 0.3s;
}

.nav li a:hover {
  /* color: rgb(129, 107, 107); */
  color: rgba(255, 255, 255, 0.6);
}

.responsive-nav {
  display: none;
}

.responsive-nav.is-active {
  position: absolute;
  background-color: #023436;
  top: 111px;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.responsive-nav a {
  color: #fff;
}
</style>